<template>
  <div class="bigbox">
    <div>
      <nav2></nav2>
    </div>
    <div class="status">
      <div style="font-size:16px">请为本次行程打分</div>
      <van-rate style="margin-top:20px"
                v-model="value"
                color="#ffd21e"
                allow-half
                void-icon="star"
                size="25px" />
      <div style="font-size:16px;margin-top:20px">请点评本次行程</div>
      <van-field v-model="message"
                 rows="2"
                 autosize
                 style="background:#FDFDFD;height:100px;width:90%;margin:20px 5%;border:solid 1px #eee; border-radius:15px"
                 type="textarea"
                 placeholder="请在此输入您的评价"
                 show-word-limit />
    </div>
    <button class="btn"
            @click="starsbtn">提交评价</button>
  </div>
</template>

<script>
import nav2 from '@/components/Nav2.vue'
import { xxdeta_api, stars_api } from '../http/api/service'
export default {
  name: 'TaxiH5OrderDetails',
  components: {
    nav2,
  },

  data () {
    return {
      value: null,
      message: '',
      nowOrder: {},
    }
  },

  mounted () {
    this.nowOrder = JSON.parse(sessionStorage.getItem('nowOrder'))
    console.log(this.nowOrder)
    //this.deta()
  },

  methods: {
    //评价
    starsbtn () {
      this.stars()
    },
    async stars () {
      let id = this.$route.query.orderid
      let carType = this.$route.query.carType
      let res = await stars_api({
        iPathOrderId: id,
        score: this.value,
        comment: this.message,
        carSource: carType,
      })
      console.log(res)
      if (res.code == 200) {
        this.$router.push({
          path: '/index',
        })
      }
    },
    async deta () {
      let res = await xxdeta_api({
        iPathOrderId: this.nowOrder.backNum, //订单号
        carSource: this.nowOrder.carType, //	打车平台
      })
      console.log(res)
    },
  },
}
</script>

<style lang="scss" scoped>
.bigbox {
  width: 100%;
  height: 100vh;
  .status {
    text-align: center;
    height: 40%;

    margin-top: 40px;
  }
  .btn {
    margin-top: 20%;
    width: 90%;
    background: #eb4541;
    margin-left: 5%;
    height: 40px;
    border: 0;
    border-radius: 15px;
    color: #fff;
    font-size: 14px;
  }
}
</style>